.centered-container {
    height: calc(100vh - 57px);
    display: flex;
    align-items: center;
}

.centered-container>* {
    width: 100%;
}

.centered-container .Button {
    margin-bottom: 15px;
}

.centered-container .Button__in {
    justify-content: space-between;
}

.centered-container .Button__content {
    width: 100%;
    text-align: left;
}