.foundry-page-top-image {
    height: 140px;
    background-size: cover;
    background-position: center;
}

.custom-progress .Progress__in {
    background-color: var(--button_commerce_background);
}

.progress-wraper {
    background: #b8c1cc40;
    border-radius: 6px;
    height: 24px;
    overflow: hidden;
}

.progress-container {
    position: relative;
}

.progress-wraper .progress-fill {
    background: #4BB34B;
    border-radius: 6px;
    height: 100%;
    width: 50%;
    text-align: right;
    color: white;
    padding: 3px 8px;
    box-sizing: border-box;
    transition: all .2s ease;
    white-space: nowrap;
    text-indent: calc(100% + 15px);
}

.progress-wraper .progress-total-text {
    position: absolute;
    right: 20px;
    top: 35px;
    color: #818C99;
    transition: top .2s ease;
}

.FixedLayout__in {
    background: white;
}