.custom-card {
    overflow: hidden;
}


.custom-card-backgroud {
    height: 140px;
    background-size: cover;
    background-position: center;
}

.news-feed-card-icons {
    display: flex;
    align-items: center;
}

.news-feed-card-icons-wraper {
    display: flex;
}

.news-feed-card-icons-wraper .news-feed-card-icons {
    margin-right: 12px;
}

.new-feed-separator {
    background-color: #EBEDF0;
    width: 100%;
    height: 10px;
}